import React from "react";

import "./BlockTextTwo.css";
import SvgDodleOne from "../../svg/SvgDodleOne";
import useWindowWidth from "../../../../hooks/useWindowWidth";
import useScrollToId from "../../../../hooks/useScrollToId";

const BlockTextTwo = ({ text, type = "white" }) => {
  const isWhite = type === "white";
  const responsive = useWindowWidth();
  const scrollById = useScrollToId();

  return (
    <div className={`${isWhite ? "container_block_two" : "container_block_two_black"}`}>
      <div className="max-width ">
        <div className="dodle_block_two">
          <SvgDodleOne color={isWhite ? "black" : "white"} width={responsive > 1024 ? "90" : "48"} />
        </div>

        <div className="limitate_size_desktop">
          <p className={` italic ${isWhite ? "text_block_two" : "text_block_two_black"}`} dangerouslySetInnerHTML={{ __html: text }}></p>
        </div>

        <div className={`${isWhite ? "line_block_two" : "line_block_two_black"}`}></div>

        {!isWhite && (
          <div className="button_one button_block_two">
            <button onClick={() => scrollById("contact")}>Contacto</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlockTextTwo;
