import React from 'react';
import './TopSection.css';
import useScrollToId from '../../../../hooks/useScrollToId';
import AnimScroll from '../../../../hooks/AnimScroll';
import { ANIM_RIGHT } from '../../Config';

const TopSection = ({ title, paragraph, img }) => {
  const scrollToId = useScrollToId();

  return (
    <div className='max-width'>
      <h1 className='title_service'>{title}</h1>

      <p className='paragraph_service'>{paragraph}</p>

      <div className='button_two button_top_section'>
        <button onClick={() => scrollToId('contact')}>Contacto</button>
      </div>

      <AnimScroll anim={ANIM_RIGHT}>
        <div className='img_service'>
          <img src={img} alt='imagen_servicio' />
        </div>
      </AnimScroll>
    </div>
  );
};

export default TopSection;
