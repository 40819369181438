import React from 'react';

import SvgDodleOne from '../../svg/SvgDodleOne';

import logo_1 from '../../../../assets/img/logos/1_paramo.png';
import logo_2 from '../../../../assets/img/logos/2_movistar.png';
import logo_3 from '../../../../assets/img/logos/3_warner.png';
import logo_4 from '../../../../assets/img/logos/4_sony.png';
import logo_5 from '../../../../assets/img/logos/5_lavalentina.png';
import logo_6 from '../../../../assets/img/logos/6_aluzian.png';
import logo_7 from '../../../../assets/img/logos/7_paramo_lab.png';
import logo_8 from '../../../../assets/img/logos/8_amarilo.png';
import logo_9 from '../../../../assets/img/logos/9_rcn_radio.png';
import logo_10 from '../../../../assets/img/logos/10_claro.png';

import './OurClients.css';
import useWindowWidth from '../../../../hooks/useWindowWidth';

const images = [logo_1, logo_2, logo_3, logo_4, logo_5, logo_6, logo_7, logo_8, logo_9, logo_10];

const OurClients = () => {
  const responsive = useWindowWidth();

  return (
    <div className='max-width'>
      <div className='flex_title_clients'>
        <SvgDodleOne width={responsive > 1024 ? '60' : '40'} />

        <div>
          <h2 className='title_clients'>
            Algunos de nuestros <span className='subtitle_clients'>clientes</span>
          </h2>
        </div>
      </div>

      <div className='container_images_clients'>
        {images.map((image, idx) => (
          <div className='image_clients' key={`image_${idx}`}>
            <img src={image} alt='imagen_logo' />
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurClients;
