import React from 'react';

import './Lines.css';
import SvgLargeArrow from '../../svg/SvgLargeArrow';
import AnimScroll from '../../../../hooks/AnimScroll';
import { ANIM_LEFT, ANIM_RIGHT } from '../../Config';

const Lines = ({ info }) => {
  return (
    <div>
      <div className='max-width'>
        <div className='title_lines'>
          <p>/Líneas/</p>
          <AnimScroll delay={500} anim={ANIM_LEFT}>
            <div className='size_arror_lines'>
              <SvgLargeArrow color='black' />
            </div>
          </AnimScroll>
        </div>

        {info.map((data, idx) => (
          <div className='line_information' key={idx}>
            <p>/0{idx + 1}/</p>
            <p>{data.title}</p>

            <AnimScroll anim={ANIM_LEFT}>
              <div className='line_decoration'></div>
            </AnimScroll>

            <div className='text_line'>
              <p>{data.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Lines;
