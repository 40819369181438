import React from "react";
import TopSection from "../../sections/topSection/TopSection";

import webMaster_img from "../../../../assets/img/services/webmaster.svg";
import OurClients from "../../sections/ourClients/OurClients";
import BlockTextOne from "../../sections/blockTextOne/BlockTextOne";
import Lines from "../../sections/lines/Lines";
import BlockTextTwo from "../../sections/blockTextTwo/BlockTextTwo";
import TestimoniesLayout from "../../sections/testimonies/TestimoniesLayout";
import ForWho from "../../sections/forWho/ForWho";
import Range from "../../sections/range/Range";
import Contacto from "../../../Contacto/Contacto";

import info_lines from "./information/line_information.json";

const WebMaster = ({ guardarWhite, guardarWhiteCaso }) => {
  return (
    <div>
      <TopSection
        title="Webmaster 360º"
        paragraph="Apoyamos a nuestros clientes en el mantenimiento y la gestión de su producto digital para sacarle el máximo provecho posible."
        img={webMaster_img}
      />

      <OurClients />

      <BlockTextOne text="/Nos gustan los <span> retos. </span>  Sabemos que la complacencia no nos hace mejores./" />

      <Lines info={info_lines} />

      <BlockTextTwo text="/Trabajamos para construir una <span> relación de largo plazo </span>  basada en la confianza con nuestros clientes, en donde tanto nuestras habilidades como nuestras limitaciones son transparentes. /" />

      <TestimoniesLayout />

      <ForWho />

      <Range />

      <div id="contact">
        <Contacto guardarWhite={guardarWhite} guardarWhiteCaso={guardarWhiteCaso} isWhite={true} bg_white={false} />
      </div>
      <div className="scroll_black"></div>
      <div className="scroll_white"></div>
    </div>
  );
};

export default WebMaster;
