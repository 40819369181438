import React from "react";

const SvgDodleOne = ({ color = "#000", width = "48" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 48.792 48.049">
      <path
        id="_002"
        data-name="002"
        d="M0,.848C14.042-.305,18.345-.288,36.259.934c7.555.516,8.226,3.7,8.587,5.025.723,2.65-3.029,5.679-5.713,6.247q-13.965,2.943-27.947,5.885a4.054,4.054,0,0,0-2.444,1.136c-1.067,1.325-.086,3.339,1.187,4.474,4.578,4.061,11.5,3.149,17.6,2.805a7.793,7.793,0,0,1,4.268.6c1.273.706,2.117,2.392,1.411,3.665-.964,1.755-4.3,2.34-3.717,4.268,3.27,2.53,7.83,2.254,11.822,3.287s8.088,5.2,6.109,8.828"
        transform="translate(0.062 0.533)"
        fill="none"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default SvgDodleOne;
