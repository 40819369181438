import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import IndioLogo from "./IndioLogo";
import $ from "jquery";

import hamburguesa_negra from "../assets/img/hamburguesa-negra.jpg";
import hamburguesa_gradacion from "../assets/img/hamburguesa-gradacion.jpg";

const subFields = [
  {
    name: "Benchmarking y auditorías",
    id: "a",
    route: "/servicios/benchmarking",
  },

  {
    name: "Diseño UX/UI",
    id: "b",
    route: "/agencia-diseno-ux-ui",
  },

  {
    name: "Desarrollo web",
    id: "c",
    route: "/servicios/desarrollo",
  },

  { name: "Webmaster 360º", id: "d", route: "/servicios/webMaster" },
];

const Menu = ({ guardarWhite, white, whiteCaso, menu }) => {
  const [menuclass, guardarClass] = useState("menu_contenedor");
  const [menucolor, guardarMenuColor] = useState(false);
  const [logoclass, guardarLogoClass] = useState("menu_contenedor");

  useEffect(() => {
    guardarWhite(false); // Establece el menú a negro
  }, [guardarWhite]);

  useEffect(() => {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      var objectSelect = $(".scroll_white");
      var objectPosition = objectSelect.offset().top - 0;
      if (scroll >= objectPosition) {
        guardarMenuColor(true);
      } else if (scroll <= objectPosition) {
        guardarMenuColor(false);
      }
    });

    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      var objectSelect = $(".scroll_black");
      var objectPosition = objectSelect.offset().top - 50;
      if (scroll >= objectPosition) {
        guardarMenuColor(false);
      }
    });

    if (white) {
      guardarClass("menu_contenedor menu-negro");
      guardarLogoClass("name_page color-white");
    } else {
      guardarClass("menu_contenedor");
      guardarLogoClass("name_page color-negro");
    }

    if (whiteCaso) {
      guardarLogoClass("name_page color-negro");
      // guardarLogoClass("name_page color-white");
    } else {
      guardarLogoClass("name_page color-negro");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [white, whiteCaso]);

  const show_menu = () => {
    const menus = document.querySelector(".menus");
    menus.classList.toggle("menus_show");
    const close_x = document.querySelector(".close_x");
    close_x.classList.toggle("span");

    const contenedor_menu = document.querySelector(".contenedor_menu");
    contenedor_menu.classList.toggle("contenedor_menu_show");

    const open_menu = document.querySelector(".open_menu");
    open_menu.classList.toggle("animacion_hambur");

    const body = document.querySelector("body");
    body.classList.toggle("scroll_none");
  };

  return (
    // Los estilos css están en index.css
    <header className={menuclass}>
      <div className="menu">
        <div className={logoclass}>
          {" "}
          <IndioLogo />
        </div>
      </div>
      {menu ? null : (
        <div className="hamburguesa" onClick={show_menu}>
          {white || menucolor ? (
            <img className="open_menu animacion_hambur" src={hamburguesa_gradacion} alt="hamburguesa" />
          ) : (
            <img className="open_menu animacion_hambur" src={hamburguesa_negra} alt="hamburguesa" />
          )}
        </div>
      )}

      {/* Menú movil */}
      <nav className="menus movil">
        <div className="menu_inside">
          <div className="contenedor_menu_inside">
            <div className={logoclass}>
              {" "}
              <IndioLogo />
            </div>
          </div>

          <label className="close" onClick={show_menu}>
            <span className="close_x">
              <svg viewBox="0 0 80 80">
                <defs />
                <defs>
                  <linearGradient id="New_Gradient_Swatch" x1="40" x2="40" y1="80" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#24748a" />
                    <stop offset="1" stopColor="#ef7a90" />
                  </linearGradient>
                </defs>
                <g id="x_cerrar" data-name="x cerrar">
                  <path id="Fondo" fill="url(#New_Gradient_Swatch)" d="M0 0h80v80H0z" />
                </g>
                <g id="linea_1-2" data-name="linea 4">
                  <path
                    id="Linea_2-2"
                    d="M17.89 18.6l14.73 14.72 23.44 23.45 5.34 5.34a.5.5 0 00.71-.71L47.38 46.68 23.94 23.23l-5.34-5.34a.5.5 0 00-.71.71z"
                    className="linea_close_svg"
                    data-name="Linea 6"
                  />
                </g>
                <g id="linea_2-3" data-name="linea 5">
                  <path
                    id="Linea_1-3"
                    d="M61.4 17.89L46.68 32.62 23.23 56.06l-5.34 5.34a.5.5 0 00.71.71l14.72-14.73 23.45-23.44 5.34-5.34a.5.5 0 00-.71-.71z"
                    className="linea_close_svg"
                    data-name="Linea 6"
                  />
                </g>
              </svg>
            </span>{" "}
          </label>
        </div>

        <ul className="contenedor_menu">
          <div className="flex_desktop_menu_one">
            <li>
              <Link
                className="item_menu"
                onClick={() => {
                  show_menu();
                  guardarWhite(false);
                }}
                to="/"
              >
                <span>01</span> Home
              </Link>
            </li>

            <li>
              <Link
                className="item_menu"
                onClick={() => {
                  show_menu();
                  guardarWhite(false);
                }}
                to="/casos"
              >
                <span>02</span> Nuestros trabajos
              </Link>
            </li>
            <li>
              <Link
                className="item_menu"
                onClick={() => {
                  show_menu();
                  guardarWhite(false);
                }}
                to="/servicios"
              >
                <span>03</span> Nuestros servicios
              </Link>

              <div className="container_sub_fields">
                {subFields.map((field, idx) => (
                  <div className="sub_fields" key={`sub_fields${idx}`}>
                    <Link
                      onClick={() => {
                        show_menu();
                        guardarWhite(false);
                      }}
                      to={field.route}
                    >
                      <span>{field.id}</span> {field.name}
                    </Link>
                  </div>
                ))}
              </div>
            </li>
          </div>

          <div className="flex_desktop_menu_two">
            <li>
              <Link
                className="item_menu"
                onClick={() => {
                  show_menu();
                  guardarWhite(false);
                }}
                to="/nosotros"
              >
                <span>04</span> Nosotros
              </Link>
            </li>
            <li>
              <Link
                className="item_menu"
                onClick={() => {
                  show_menu();
                  guardarWhite(false);
                }}
                to="/contacto"
              >
                <span>05</span> Contacto
              </Link>
              {/* <a className="item_menu" href="https://indio.guru/contacto">
                <span>05</span> Contrátenos
              </a> */}
            </li>

            <li>
              <a className="item_menu" href="https://indio.guru/oferta">
                <span>06</span> Trabaja con nosotros
              </a>
            </li>
          </div>
        </ul>

        <div className="bottom_menu">© Indio.guru</div>
      </nav>
    </header>
  );
};

export default Menu;
