import React, { useRef } from "react";
import { Slide } from "react-slideshow-image";
import "./Slideshow.css";

import boton_atras from "../../assets/img/boton_atras.png";
import boton_siguiente_slide from "../../assets/img/boton_siguiente_slide.png";
import estrategia_servicios from "../../assets/img/Acerca/estrategia_servicios.png";
import diseño_servicios from "../../assets/img/Acerca/diseño_servicios.png";
import desarrollo_web_servicios from "../../assets/img/Acerca/desarrollo-web_servicios.png";
import doodle_two from "../../assets/img/doodle_two.svg";

import "../../components/Servicios/styles/SliderHard.css";

import "react-slideshow-image/dist/styles.css";
import { Link } from "react-router-dom";

const Slideshow = () => {
  const slideRef = useRef();
  const fadeProperties = {
    canSwipe: false,
    autoplay: true,
    duration: 7000,
    arrows: false,
    transitionDuration: 200,
    onChange: (previous, next) => {
      const slide_1 = document.querySelector("#slide_1 img");
      const slide_2 = document.querySelector("#slide_2 img");
      const slide_3 = document.querySelector("#slide_3 img");
      const slide_4 = document.querySelector("#slide_4 img"); // Nuevo selector para el cuarto slide
      const circulo_fondo = document.querySelector(".circulo_fondo");
      const linea_progreso = document.querySelector(".linea_progreso");

      // Restablece las clases para todos los slides y el indicador de progreso
      circulo_fondo.classList.remove("left_3", "left_26", "left_72", "left_100"); // Agrega una nueva clase para la posición del cuarto círculo
      linea_progreso.classList.remove("width_25", "width_50", "width_75", "width_100"); // Agrega 'width_75' para el tercer slide
      [slide_1, slide_2, slide_3, slide_4].forEach((slide) => slide.classList.remove("scale")); // Incluye slide_4 en la iteración

      switch (next) {
        case 0:
          circulo_fondo.classList.add("left_3");
          linea_progreso.classList.add("width_25");
          slide_1.classList.add("scale");
          break;
        case 1:
          circulo_fondo.classList.add("left_26");
          linea_progreso.classList.add("width_50");
          slide_2.classList.add("scale");
          break;
        case 2:
          circulo_fondo.classList.add("left_72");
          linea_progreso.classList.add("width_75");
          slide_3.classList.add("scale");
          break;
        case 3:
          circulo_fondo.classList.add("left_100");
          linea_progreso.classList.add("width_100");
          slide_4.classList.add("scale");
          break;
      }
    },
  };
  const back = () => {
    slideRef.current.goBack();
  };

  const next = () => {
    slideRef.current.goNext();
  };

  const goto = (target) => {
    slideRef.current.goTo(parseInt(target, 10));
  };

  return (
    <div className="galeria_contenedor">
      <div className="slide-container">
        <Slide ref={slideRef} {...fadeProperties}>
          <div className="each-fade">
            <ul className="servicios">
              <div>
                <span>Investigación y benchmarking</span>
                En esta etapa buscamos las pistas planteadas en el brief para encontrar las posibles respuestas y plantear una solución digital.
                <hr />
                <li>Benchmarking de competidores web</li>
                <li>Pruebas de usabilidad</li>
                <li>Auditoría de heurísticas UX</li>
                <li>Auditoría de diseño UI</li>
                <li>Auditoría SEO</li>
              </div>
              <div className="button_container">
                <Link to="/servicios/benchmarking">Ver más</Link>
              </div>
            </ul>
          </div>
          <div className="each-fade">
            <ul className="servicios">
              <div>
                <span>Diseño UX/UI</span>
                Acá definimos la estructura, las lógicas de uso y navegación, las reglas visuales y creamos un prototipo del producto.
                <hr />
                <li>Arquitectura</li>
                <li>Wireframing</li>
                <li>Sistema de diseño</li>
                <li>Interfaz de usuario</li>
              </div>
              <div className="button_container">
                <Link to="/agencia-diseno-ux-ui">Ver más</Link>
              </div>
            </ul>
          </div>
          <div className="each-fade">
            <ul className="servicios">
              <div>
                <span>Desarrollo web</span>
                Nuestro equipo de desarrollo es experto en front-end para crear interfaces de usuario fieles al diseño UI y también se enfoca en un
                back-end sólido. Son detallistas, rigurosos y accesibles.
                <hr />
                <li>ReactJS</li>
                <li>Wordpress</li>
                <li>E-commerce</li>
                <li>Three.js</li>
                <li>Experiencias inmersivas AR/VR</li>
                <li>Animaciones web</li>
              </div>
              <div className="button_container">
                <Link to="/servicios/desarrollo">Ver más</Link>
              </div>
            </ul>
          </div>

          <div className="each-fade">
            <ul className="servicios">
              <div>
                <span>Webmaster 360º</span>
                Apoyamos a nuestros clientes en el mantenimiento y la gestión de su producto digital para sacarle el máximo provecho posible.
                <hr />
                <li>Gestión de contenidos </li>
                <li>Soporte web</li>
                <li>Estrategia SEO</li>
                <li>Estrategia SEM</li>
              </div>
              <div className="button_container">
                <Link to="/servicios/webMaster">Ver más</Link>
              </div>
            </ul>
          </div>
        </Slide>
        <div className="linea_progreso"></div>
      </div>

      <div className="icons_active">
        <div className="circulo_fondo left_3"></div>
        <div
          id={"slide_1"}
          className="active_slide"
          onClick={() => {
            goto(0);
          }}
        >
          <img src={estrategia_servicios} alt="icono" />
        </div>

        <div
          id={"slide_2"}
          onClick={() => {
            goto(1);
          }}
        >
          <img src={diseño_servicios} alt="icono" />
        </div>

        <div
          id={"slide_3"}
          onClick={() => {
            goto(2);
          }}
        >
          <img src={desarrollo_web_servicios} alt="icono" />
        </div>
        <div
          id={"slide_4"}
          onClick={() => {
            goto(3);
          }}
        >
          <img src={doodle_two} alt="icono" />
        </div>
      </div>

      <div className="autoplay-buttons">
        <button className="boton" onClick={back}>
          <img src={boton_atras} alt="atras" />
        </button>
        <button onClick={next}>
          <img src={boton_siguiente_slide} alt="siguiente" />
        </button>
      </div>
    </div>
  );
};

export default Slideshow;
