import React, { useEffect, useRef } from "react";

const AnimScroll = ({ children, anim, delay, opacity = true }) => {
  const elementRef = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const addAnimationClass = () => {
            entry.target.classList.add("animate__animated");
            entry.target.classList.add(anim);
            observer?.unobserve(entry.target);
          };

          if (delay) {
            setTimeout(addAnimationClass, delay);
          } else {
            addAnimationClass();
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (elementRef.current) {
      observer?.observe(elementRef.current);

      return () => {
        if (elementRef.current && observer && observer.unobserve) {
          observer.unobserve(elementRef.current);
        }
      };
    }
  }, [anim, delay]);

  return (
    <div ref={elementRef} className={`${opacity && "animated-element"}`}>
      {children}
    </div>
  );
};

export default AnimScroll;
