import { useEffect, useState } from "react";
import Footer from "../Footer";
import boton_flecha_siguiente from "../../assets/img/boton_flecha_siguiente.png";
import "./Servicio.css";
import $ from "jquery";
import flecha_abajo from "../../assets/img/Home/flecha-abajo.png";
import { useParams, Link } from "react-router-dom";
import servicios from "./Servicios.json";
export const Servicio = ({ white, guardarWhite, guardarWhiteCaso }) => {
  const [servicio, setServicio] = useState({});

  const { id } = useParams();

  useEffect(() => {
    const servicioFound = servicios.filter((servicio) => servicio.slug === id);
    setServicio(servicioFound[0]);
  }, [servicios]);

  useEffect(() => {
    if (Object.keys(servicio).length > 0) {
      window.scrollTo(0, 0);

      // Scroll para about
      $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        var objectSelect = $(".scroll_white");
        var objectPosition = objectSelect.offset().top - 500;
        if (scroll >= objectPosition) {
          $(".about_fixed").addClass("change_white_color");
        } else {
          $(".about_fixed").removeClass("change_white_color");
        }
      });

      $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        var objectSelect = $(".scroll_black");
        var objectPosition = objectSelect.offset().top - 600;
        if (scroll >= objectPosition && $(".about_fixed").hasClass("change_white_color")) {
          $(".about_fixed").removeClass("change_white_color");
        }
      });

      $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        var objectSelect = $(".scroll_animacion");
        var objectPosition = objectSelect.offset().top - 700;
        if (scroll >= objectPosition) {
          $(".about_fixed").addClass("fadeout");
        } else {
          $(".about_fixed").removeClass("fadeout");
        }
      });

      // scroll para linea color manifesto
      $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        var objectSelect = $(".scroll_white");
        var objectPosition = objectSelect.offset().top - 300;
        if (scroll >= objectPosition) {
          $(".linea_color").addClass("linea_color_top_5");
        } else {
          $(".linea_color").removeClass("linea_color_top_5");
        }
      });

      $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        var objectSelect = $(".scroll_white");
        var objectPosition = objectSelect.offset().top - 0;
        if (scroll >= objectPosition) {
          $(".linea_color").addClass("linea_color_top_9");
        } else {
          $(".linea_color").removeClass("linea_color_top_9");
        }
      });

      guardarWhite(false);
      guardarWhiteCaso(false);
    }
  }, [servicio, guardarWhite, guardarWhiteCaso]);

  const animation = () => {
    const fondo_blanco = document.querySelector(".fondo_blanco");
    fondo_blanco.classList.add("fondo_blanco_animation");
    const fondo_blanco_top = document.querySelector(".fondo_blanco_top");
    fondo_blanco_top.classList.add("fondo_blanco_animation_top");
  };

  const nextCase = (caso) => {
    const flecha_imagen = document.querySelector(".next_caso img");
    flecha_imagen.classList.add("flecha_caso_animacion");
    setTimeout(function () {
      window.location.href = `https://indio.guru/contacto`;
    }, 2600);
  };

  return (
    <section id="servicio">
      <div className="animacion_contenedor">
        <div className="animacion">
          <svg viewBox="0 0 397.75 33.5">
            <defs>
              <linearGradient id="a" x1="89.75" x2="397.75" y1="5.75" y2="5.75" gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#24748a" />
                <stop offset="1" stopColor="#ef7a90" />
              </linearGradient>
              <linearGradient id="d" x1="357.596" x2="391.096" y1="55.961" y2="56.036" gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#24748a" />
                <stop offset=".31" stopColor="#60768c" />
                <stop offset="1" stopColor="#ef7a90" />
              </linearGradient>
            </defs>
            <g fill="none" strokeMiterlimit="10" strokeWidth="1.5">
              <path className="home_lineas_10" stroke="url(#a)" d="M89.75 5.75h308" />
              <path className="home_lineas_10" stroke="url(#a)" d="M89.75 16.75h308" />
              <path className="home_lineas_10" stroke="url(#a)" d="M89.75 27.75h308" />
            </g>
            <path
              className="home_lineas_2"
              fill="url(#d)"
              d="M375 39.25A16.75 16.75 0 10391.75 56 16.769 16.769 0 00375 39.25zM359.75 56a15.256 15.256 0 0114.5-15.212v30.424A15.256 15.256 0 01359.75 56zm16 15.212V40.788a15.229 15.229 0 010 30.424z"
              transform="translate(-358.25 -39.25)"
            />
          </svg>
        </div>
        <div className="clearfix"></div>
      </div>

      <>
        <span className="about_fixed">- {servicio.titulo_fixed} </span>

        <div className="servicio_header max-width">
          <h2>{servicio.titulo}</h2>

          <div className="flex_container">
            <p>{servicio.subtitulo}</p>
          </div>
        </div>

        <div className="scroll_white"></div>
        <div className="manifesto">
          {/* <div className="max-width"> */}

          <div className="content_manifesto" style={{ background: "white", height: "0px", padding: "0px" }}></div>

          {servicio.caracteristicas && (
            <div className="content_manifesto max-width ">
              <>
                {servicio.caracteristicas.map((item) => (
                  <div className="item_caracteristica">
                    <p className="titulo">{item.titulo}</p>
                    <div className="descripcion_container">
                      <p>{item.descripcion}</p>
                    </div>
                  </div>
                ))}
              </>
              <div className="button_container">
                <div
                  className="button"
                  onClick={() => {
                    nextCase("acerca");
                    animation();
                  }}
                >
                  Contrátenos
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="scroll_black"></div>

        <div
          onClick={() => {
            nextCase("acerca");
            animation();
          }}
          className="next_caso"
        >
          <img className="flecha_caso_animacion" src={boton_flecha_siguiente} alt="siguiente" />

          <p>="Contrátenos"</p>
        </div>
        <div class="scroll_animacion_2"></div>
        <div className="fondo_blanco fondo_negro"></div>
        <div className="fondo_blanco_top fondo_negro"></div>
        <Footer white={white} />
      </>
    </section>
  );
};
