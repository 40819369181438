import React, { useRef } from 'react';
import './SomeOurProjects.css';

import { Swiper, SwiperSlide } from 'swiper/react';

import arrow from '../../../../assets/img/boton_flecha_siguiente.png';
import 'swiper/css';
import 'swiper/css/navigation';

import { Navigation } from 'swiper/modules';
import useWindowWidth from '../../../../hooks/useWindowWidth';
import AnimScroll from '../../../../hooks/AnimScroll';
import { ANIM_DOWN } from '../../Config';
import SvgSlash from '../../../../components/Servicios/svg/SvgSlash';

const SomeOurProjects = ({ info, type }) => {
  return (
    <div>
      <div className='max-width container_our_projects'>
        <div className='flex_title_projects'>
          <div className='w_slash_p'>
            <SvgSlash />
          </div>
          <h2 className='title_our_projects'>Algunos proyectos</h2>
        </div>
        <AnimScroll anim={ANIM_DOWN}>{type === 1 ? <SliderOne info={info} /> : <SliderTwo info={info} />}</AnimScroll>
      </div>
    </div>
  );
};

export default SomeOurProjects;

const SliderOne = ({ info }) => {
  const responsive = useWindowWidth();

  return (
    <div className='container_slider_one'>
      <Swiper
        navigation={{
          nextEl: '.custom-next',
          prevEl: '.custom-prev',
        }}
        modules={[Navigation]}
        slidesPerView={responsive < 764 ? '1' : '2'}
        className='swiper_one'
        spaceBetween={100}
      >
        <div className='arrows_slider_one'>
          <div className='custom-prev'>
            <img src={arrow} alt='Anterior' />
          </div>
          <div className='custom-next'>
            <img src={arrow} alt='Siguiente' />
          </div>
        </div>

        {info.map((slider, indx) => (
          <div key={`slider_${indx}`}>
            <SwiperSlide className='swiper_slider_one'>
              <div className='decoration_border_slider_one'></div>
              <div className='contet_slider_one'>
                <div className='img_slider_one'>
                  <img src={slider.imgs} alt='images' />
                </div>
                <div className='info_slider_one'>
                  <p>{slider.name}</p>
                  {slider.logo ? <img src={slider.logo} alt='logo' /> : <div className='space_no_img'></div>}
                </div>
              </div>
            </SwiperSlide>
          </div>
        ))}
      </Swiper>
    </div>
  );
};

const SliderTwo = ({ info }) => {
  const responsive = useWindowWidth();

  return (
    <div className='container_slider_one'>
      <Swiper
        navigation={{
          nextEl: '.custom-next',
          prevEl: '.custom-prev',
        }}
        modules={[Navigation]}
        slidesPerView={responsive < 764 ? '1' : '2'}
        className='swiper_one'
        spaceBetween={100}
      >
        <div className='arrows_slider_one'>
          <div className='custom-prev'>
            <img src={arrow} alt='Anterior' />
          </div>
          <div className='custom-next'>
            <img src={arrow} alt='Siguiente' />
          </div>
        </div>

        {info.map((slider, indx) => (
          <div key={`Slider_two_${indx}`}>
            <SwiperSlide className='swiper_slider_two'>
              <div className='decoration_border_slider_one'></div>
              <div className='contet_slider_one'>
                <div className='img_slider_two'>
                  <img src={slider?.imgs} alt='imagenes' />
                </div>

                <div className='info_slider_one'>
                  <p>{slider.name}</p>
                  <img src={slider.logo} alt='flecha' />
                </div>
              </div>
            </SwiperSlide>
          </div>
        ))}
      </Swiper>
    </div>
  );
};
