import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import flecha_abajo from '../../assets/img/Home/flecha-abajo.png';
import arrow_down_black from '../../assets/img/arrow_down_black.svg';
import Footer from '../Footer';
import './ContactWhite.css';
import './Contacto.css';

const Contacto = ({ white, guardarWhiteCaso, guardarWhite, isWhite = false, bg_white = true, isFooter = true }) => {
  const [formData, setFormData] = useState({});
  const [error, setError] = useState('');
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    guardarWhite(bg_white);
    guardarWhiteCaso(true);

    const form = document.getElementById('gform');
    if (form) {
      form.addEventListener('submit', handleFormSubmit, false);
    }

    return () => {
      if (form) {
        form.removeEventListener('submit', handleFormSubmit);
      }
    };
  }, [guardarWhiteCaso, guardarWhite]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const data = getFormData();
    const requiredFields = ['Nombre', 'Apellido', 'email', 'Telefono', 'Empresa', 'Mensaje'];
    const missingFields = requiredFields.filter((field) => !data[field] || data[field].trim() === '');

    if (missingFields.length > 0) {
      setError(`Por favor, completa todos los campos obligatorios: ${missingFields.join(', ')}`);
      return;
    } else {
      setError('');
      disableAllButtons(event.target);

      const url = event.target.action;
      const xhr = new XMLHttpRequest();
      xhr.open('POST', url);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            history.push('/gracias');
          } else {
            setError('Ocurrió un error al enviar el formulario. Inténtalo nuevamente.');
          }
        }
      };

      const encoded = Object.keys(data)
        .map(function (k) {
          return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]);
        })
        .join('&');
      xhr.send(encoded);
    }
  };

  const getFormData = () => {
    const form = document.getElementById('gform');
    const elements = form.elements;

    const fields = Object.keys(elements)
      .filter((k) => elements[k].name !== 'honeypot')
      .map((k) => (elements[k].name !== undefined ? elements[k].name : elements[k].item(0).name))
      .filter((item, pos, self) => self.indexOf(item) === pos && item);

    const formData = {};
    fields.forEach((name) => {
      const element = elements[name];
      formData[name] = element.value;
      if (element.length) {
        const data = [];
        for (let i = 0; i < element.length; i++) {
          const item = element.item(i);
          if (item.checked || item.selected) {
            data.push(item.value);
          }
        }
        formData[name] = data.join(', ');
      }
    });

    formData.formDataNameOrder = JSON.stringify(fields);
    formData.formGoogleSheetName = form.dataset.sheet || 'responses';
    formData.formGoogleSendEmail = form.dataset.email || '';

    return formData;
  };

  const disableAllButtons = (form) => {
    const buttons = form.querySelectorAll('button, input[type="submit"]');
    buttons.forEach((button) => (button.disabled = true));
  };

  return (
    <div>
      <section id={isWhite ? 'contact_white' : 'contacto'} className='max-width'>
        <div className='contacto_header'>
          <div>{isWhite ? <img className='arrow_black_contact' src={arrow_down_black} alt='flecha' /> : <img src={flecha_abajo} alt='flecha' />}</div>
          <p className='italic title_contact'>
            Nos encantaría <br /> conocer su proyecto.
            <span>/¡hablemos!/</span>
          </p>
        </div>

        <div className='formulario'>
          <form id='gform' data-email='' action='https://script.google.com/macros/s/AKfycbyXT9TCZgY9ah7Ee9QvANuyxznP1p5Ni09aGIM-q5C6oIWyZMkJ/exec'>
            <div className='formInputs'>
              <div className='campos'>
                <input type='text' placeholder='Nombre' name='Nombre' />
              </div>

              <div className='campos'>
                <input type='text' placeholder='Apellido' name='Apellido' />
              </div>

              <div className='campos'>
                <input type='email' placeholder='E-mail' name='email' />
              </div>

              <div className='campos'>
                <input type='tel' placeholder='Télefono móvil' name='Telefono' />
              </div>

              <div className='campos'>
                <input type='text' placeholder='Empresa' name='Empresa' />
              </div>

              <div className='campos'>
                <textarea name='Mensaje' cols='30' rows='10' placeholder='Cómo podemos ayudar'></textarea>
              </div>

              {error && <div className='error-message'>{error}</div>}
              <div className='campos'>
                <input type='submit' value='Enviar' />
              </div>
            </div>

            <div style={{ display: 'none' }} className='thankyou_message'>
              <h2>
                <em> Gracias </em> por contactarnos. ¡Nos pondremos en contacto con usted pronto!
              </h2>
            </div>
          </form>
        </div>
        <div className='clearfix'></div>
      </section>

      {isFooter && <Footer white={white} />}
    </div>
  );
};

export default Contacto;
