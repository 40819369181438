import React, { useState, useEffect } from 'react';

import './App.css'
import Routes from './components/Routes/Routes';




function App() {

  const [white, guardarWhite] = useState(false);
  const [whiteCaso, guardarWhiteCaso] = useState(true);
  const [classApp, guardarClass] = useState('');
  const [menu, mostrarMenu] = useState(false);

  useEffect(() => {
    if (white) {
      guardarClass('App app-black');
    } else {
      guardarClass('App');

    }
  }, [white])


  return (
    <div className={classApp}>
      <Routes
        menu={menu}
        white={white}
        whiteCaso={whiteCaso}
        guardarWhite={guardarWhite}
        guardarWhiteCaso={guardarWhiteCaso}
        mostrarMenu={mostrarMenu}
      />
    </div>
  );
}

export default App;
