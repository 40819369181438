import React from "react";

import "./BlockTextOne.css";
import useScrollToId from "../../../../hooks/useScrollToId";

const BlockTextOne = ({ text, type = 1 }) => {
  const isOneType = type === 1;
  const scrollToId = useScrollToId();
  return (
    <div className="container_block_text_one">
      <div className="max-width">
        <div className="size_desktop_blok_one">
          <p className={` italic ${isOneType ? "text_block_one" : "text_block_one_typeTwo"}`} dangerouslySetInnerHTML={{ __html: text }}></p>
        </div>

        <div className="button_one button_block_one">
          <button onClick={() => scrollToId("contact")}>Contacto</button>
        </div>

        <div className="line_block_one"></div>
      </div>
    </div>
  );
};

export default BlockTextOne;
