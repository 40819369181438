import React, { useEffect } from 'react'
import './Error.css'
import { Link } from 'react-router-dom';
import indio_page_404 from '../../assets/img/indio-page-404.png'


const Error = ({ mostrarMenu, guardarWhite, guardarWhiteCaso }) => {

    useEffect(() => {
        window.scrollTo(0, 0);
        guardarWhite(true);
        guardarWhiteCaso(true)
        mostrarMenu(true)
    }, [mostrarMenu, guardarWhite, guardarWhiteCaso])


    return (
        <section id="error">
            <div className="error_mensaje">
                <div>
                    <span>/ups!/</span>
                    <span>error</span>
                </div>
                <div>404</div>
            </div>

            <div className="error_imagen">
                <img src={indio_page_404} alt="indio"/>
            </div>

            <div className="error_menu">
                <div>p=<span className="pink">”not found”</span> </div>
                <div><Link className="link" to="/">Volver al inicio</Link> </div>
            </div>


            <div className="scroll_white"></div>
            <div className="scroll_black"></div>
            <div className="scroll_animacion"></div>
        </section>
    );
}

export default Error;