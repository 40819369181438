import React from 'react';

import consultoras from '../../../../assets/img/para_quienes/branding.svg';
import disqueros from '../../../../assets/img/para_quienes/sellos.svg';
import eventos from '../../../../assets/img/para_quienes/productoras.svg';
import arquitectura from '../../../../assets/img/para_quienes/arquitectura.svg';
import publicidad from '../../../../assets/img/para_quienes/publicidad.png';

import './forWho.css';
import SvgAim from '../../svg/SvgAim';
import AnimScroll from '../../../../hooks/AnimScroll';
import { ANIM_DOWN } from '../../Config';

const entities = [
  { name: 'Consultoras de branding', img: consultoras },
  { name: 'Agencias de publicidad', img: publicidad },
  { name: 'Sellos disqueros', img: disqueros },
  { name: 'Productora de eventos', img: eventos },
  { name: 'Estudios de arquitectura y constructoras', img: arquitectura },
];

const ForWho = () => {
  return (
    <div className='container_all'>
      <div className='max-width'>
        <div className='content_title'>
          <div className='figure_size_for'>
            <SvgAim />
          </div>
          <h2 className='title_for'>Para quiénes</h2>
        </div>

        <p className='paragraph_for'>
          Nuestros clientes son empresas buscando soluciones altamente creativas que amplíen y complementen el espíritu de sus marcas. Nos hemos
          especializado en trabajar principalmente con:
        </p>

        <div className='container_slider'>
          {entities.map((item, index) => (
            <div key={`who_${index}`}>
              <AnimScroll anim={ANIM_DOWN}>
                <div className='container_img'>
                  <img src={item.img} alt='imagen' />
                </div>
                <p className='text_who'>{item.name}</p>
              </AnimScroll>
            </div>
          ))}
        </div>
      </div>

      <div className='line_decoration_forwho'></div>
    </div>
  );
};

export default ForWho;
