import { useCallback } from "react";

const useScrollToId = () => {
  const scrollToId = useCallback((id) => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);

  return scrollToId;
};

export default useScrollToId;
