import React from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';

import Menu from '../Menu';
import ListaCasos from '../Home/ListaCasos';
import Acerca from '../Acerca/Acerca';
import Contacto from '../Contacto/Contacto';
import Home from '../Home/Home';
import Error from '../Error/Error';
import Gracias from '../Gracias/Gracias';
import Caso from '../Casos/Caso';
import { Servicios } from '../Servicios/Servicios';
import { Servicio } from '../Servicios/Servicio';
import BenchMarking from '../Servicios/pages/benchmarking/BenchMarking';
import Desing from '../Servicios/pages/desing/Desing';
import DevelopmentWeb from '../Servicios/pages/development/DevelopmentWeb';
import WebMaster from '../Servicios/pages/webMaster/WebMaster';

function Routes({ menu, white, whiteCaso, guardarWhite, guardarWhiteCaso, mostrarMenu }) {
  return (
    <Router>
      <Menu menu={menu} white={white} whiteCaso={whiteCaso} guardarWhite={guardarWhite} mostrarMenu={mostrarMenu} />
      <Switch>
        <Route exact path='/nosotros'>
          <Acerca guardarWhite={guardarWhite} guardarWhiteCaso={guardarWhiteCaso} mostrarMenu={mostrarMenu} white={white} />
        </Route>

        <Route exact path='/servicios'>
          <Servicios guardarWhite={guardarWhite} guardarWhiteCaso={guardarWhiteCaso} mostrarMenu={mostrarMenu} white={white} />
        </Route>

        <Route exact path='/servicios/benchmarking'>
          <BenchMarking guardarWhite={guardarWhite} guardarWhiteCaso={guardarWhiteCaso} />
        </Route>

        <Route exact path='/agencia-diseno-ux-ui'>
          <Desing guardarWhite={guardarWhite} guardarWhiteCaso={guardarWhiteCaso} />
        </Route>

        <Route exact path='/servicios/desarrollo'>
          <DevelopmentWeb guardarWhite={guardarWhite} guardarWhiteCaso={guardarWhiteCaso} />
        </Route>

        <Route exact path='/servicios/webMaster'>
          <WebMaster guardarWhite={guardarWhite} guardarWhiteCaso={guardarWhiteCaso} />
        </Route>
        <Route exact path='/servicios/servicio/:id'>
          <Servicio guardarWhite={guardarWhite} guardarWhiteCaso={guardarWhiteCaso} mostrarMenu={mostrarMenu} white={white} />
        </Route>

        <Route exact path='/contacto'>
          <Contacto guardarWhite={guardarWhite} guardarWhiteCaso={guardarWhiteCaso} mostrarMenu={mostrarMenu} white={white} />
        </Route>
        <Route exact path='/'>
          <Home guardarWhite={guardarWhite} guardarWhiteCaso={guardarWhiteCaso} mostrarMenu={mostrarMenu} white={white} />
        </Route>

        <Route exact path='/casos'>
          <ListaCasos white={white} />
        </Route>

        <Route exact path='/proyecto/:name/:number'>
          <Caso guardarWhite={guardarWhiteCaso} />
        </Route>

        <Route exact path='/gracias'>
          <Gracias guardarWhite={guardarWhite} guardarWhiteCaso={guardarWhiteCaso} mostrarMenu={mostrarMenu} white={white} />
        </Route>

        <Route exact path='*'>
          <Error guardarWhite={guardarWhite} guardarWhiteCaso={guardarWhiteCaso} mostrarMenu={mostrarMenu} />
        </Route>
      </Switch>
    </Router>
  );
}

export default Routes;
