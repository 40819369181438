import React from 'react';

import './workWithUs.css';

import info_work from './infoWorkWithUs.json';

import SvgFigureOne from '../../svg/SvgFigureOne';
import useWindowWidth from '../../../../hooks/useWindowWidth';
import AnimScroll from '../../../../hooks/AnimScroll';
import { ANIM_LEFT } from '../../Config';

const WorkWithUs = () => {
  const responsive = useWindowWidth();

  return (
    <div className='container_all_work'>
      <div className='max-width'>
        <div className='container_title_work'>
          <p className='title_work italic'>/Por qué trabajar con nosotros/</p>
          <div className='figure_work'>
            <SvgFigureOne />
          </div>
        </div>

        <div className='container_info_work'>
          {info_work.map((item, index) => (
            <div className='info_work' key={`work_${index}`}>
              <div className='title_info_work'>
                <p>/0{index + 1}</p>

                <div className='flex_info_two'>
                  <p> {item.title}</p>

                  <AnimScroll anim={ANIM_LEFT}>
                    <div className=''>{responsive > 1024 && <p className='line_decoration_work_desktop'></p>}</div>
                  </AnimScroll>

                  <div className='paragraph_work'>
                    <p>{item.paragrapgh}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WorkWithUs;
