import React from "react";

import "./Range.css";
import SvgSlash from "../../svg/SvgSlash";

import range_img from "../../../../assets/img/services/rango_accion.svg";

const Range = () => {
  return (
    <div className="max-width">
      <div className="flex_range_desktop">
        <div className="group">
          <div className="title_range">
            <div className="slash_range">
              <SvgSlash />
            </div>
            <p>Nuestro rango de acción</p>
          </div>
          <p className="paragraph_range">
            Abarca desde un benchmarking hasta el desarrollo de páginas, landings, web apps, apps nativas, e-commerces o experiencias inmersivas
            AR/VR.
          </p>
        </div>

        <div className="img_range">
          <img className="" src={range_img} alt="rango_accion" />
        </div>
      </div>
    </div>
  );
};

export default Range;
