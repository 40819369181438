import Slideshow from '../slideshow/Slideshow';
import Footer from '../Footer';
import './Servicios.css';
import img_product from '../../assets/img/services/productos.svg';
import './styles/Services.css';

import 'animate.css';

import OurClients from './sections/ourClients/OurClients';
import TestimoniesLayout from './sections/testimonies/TestimoniesLayout';
import ForWho from './sections/forWho/ForWho';
import WorkWithUs from './sections/workWithUs/WorkWithUs';
import Contacto from '../../components/Contacto/Contacto';
import SvgFigureOne from './svg/SvgFigureOne';
import useWindowWidth from '../../hooks/useWindowWidth';
import SvgLargeArrow from './svg/SvgLargeArrow';
import SomeOurProjects from './sections/someOurProjects/SomeOurProjects';

import img_one_feb from '../../assets/img/projects/proyectoos_ux/1_fep.png';
import img_one_movistar from '../../assets/img/projects/proyectoos_ux/2_movistar_arena.png';
import img_one_productos from '../../assets/img/projects/proyectoos_ux/3_pa.png';
import img_one_sama from '../../assets/img/projects/proyectoos_ux/4_sama.png';
import img_one_cordillera from '../../assets/img/projects/proyectoos_ux/5_cordillera.png';

import logo_movistar from '../../assets/img/logos_blancos/6_movistar.png';
import logo_paramo from '../../assets/img/logos_blancos/5_paramopresenta.png';
import logo_productos from '../../assets/img/logos_blancos/7_pa.png';
import logo_sama from '../../assets/img/logos_blancos/8_sama.png';

import useScrollToId from '../../hooks/useScrollToId';
import AnimScroll from '../../hooks/AnimScroll';
import { ANIM_DOWN_NORMAL, ANIM_RIGHT, ANIM_ROTATE } from './Config';

export const Servicios = ({ white, guardarWhite, guardarWhiteCaso }) => {
  const scrollById = useScrollToId();
  const responsive = useWindowWidth();

  const services = ['Páginas web', 'Landing pages', 'Experiencias inmersivas AR/VR', 'Web apps', 'Apps nativas', 'Ecommerce'];

  return (
    <section id='servicios' className='container_services'>
      <span className='about_fixed'>-Servicios</span>

      <div className='max-width'>
        <div className='line_color'></div>

        <h2 className='title'>Servicios</h2>

        <div className='flex_container'>
          <div className='paragraph_services'>
            <div className='paragraph_services_text'>
              <AnimScroll anim={ANIM_RIGHT}>
                <p>
                  Nuestro equipo se ha especializado en crear entornos web bellos y poderosos dentro de un rango de acción que va desde benchmarking
                  hasta desarrollo de páginas web, landing pages, web apps, e-commerce o activaciones digitales.
                </p>
              </AnimScroll>
            </div>
          </div>
        </div>
      </div>

      <div className='galeria max-width'>
        <Slideshow />
      </div>

      <div className='scroll_white'></div>
      <div className='bg_black_all'>
        <div className='products max-width'>
          <div className='title_products'>
            <h2 className='italic'>/Productos/</h2>
            <div>
              <AnimScroll anim={ANIM_ROTATE}>
                <SvgFigureOne />
              </AnimScroll>
            </div>
          </div>

          <p className='paragraph_products'>A través de las tres etapas de los proyectos, creamos algunos de los siguientes productos digitales:</p>

          <p className='absolute_text_products'>-- Productos</p>

          <div className='flex_list_products'>
            {responsive > 1024 && (
              <div className='container_arrow_products'>
                <AnimScroll anim={ANIM_DOWN_NORMAL}>
                  <div className='arrow_destok_products'>
                    <SvgLargeArrow />
                  </div>
                </AnimScroll>
              </div>
            )}
            <ul className='list_products_contain'>
              {services.map((service, index) => (
                <li className='list_products' key={index}>
                  <p>/0{index + 1}</p>
                  <p>{service}</p>
                </li>
              ))}
              <div className='button_one button_products'>
                <button onClick={() => scrollById('contact')}>Contacto</button>
              </div>
            </ul>
          </div>

          <AnimScroll anim='animate__fadeInUp'>
            <div className='img_products'>
              <img src={img_product} alt='imagen producto'></img>
            </div>
          </AnimScroll>
        </div>
      </div>

      <OurClients />
      <TestimoniesLayout />
      <ForWho />
      <SomeOurProjects info={information_slider} type={2} />
      <WorkWithUs />

      <div id='contact'>
        <Contacto guardarWhite={guardarWhite} guardarWhiteCaso={guardarWhiteCaso} isWhite={true} bg_white={false} isFooter={false} />
      </div>

      <div className='scroll_black'></div>

      <Footer white={white} />
    </section>
  );
};

const information_slider = [
  {
    name: 'Festival Estéreo Picnic 2024',
    imgs: img_one_feb,
    logo: logo_paramo,
  },
  {
    name: 'Movistar Arena',
    imgs: img_one_movistar,
    logo: logo_movistar,
  },
  {
    name: 'Productos Arquitectónicos',
    imgs: img_one_productos,
    logo: logo_productos,
  },
  {
    name: 'Sama Sacred Music',
    imgs: img_one_sama,
    logo: logo_sama,
  },
  {
    name: 'Festival Cordillera',
    imgs: img_one_cordillera,
    logo: logo_paramo,
  },
];
