import React from "react";

const SvgAim = () => {
  return (
    <svg id="_001" data-name="001" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 51.918 51.92">
      <ellipse
        id="Elipse_5"
        data-name="Elipse 5"
        cx="13.595"
        cy="13.595"
        rx="13.595"
        ry="13.595"
        transform="translate(12.373 12.356)"
        fill="none"
        stroke="#FFFFFF"
        strokeMiterlimit="10"
       strokeWidth="1.5"
      />
      <line
        id="Línea_21"
        data-name="Línea 21"
        y2="51.919"
        transform="translate(25.968)"
        fill="none"
        stroke="#FFFFFF"
        strokeMiterlimit="10"
       strokeWidth="1.5"
      />
      <line
        id="Línea_22"
        data-name="Línea 22"
        x1="51.918"
        transform="translate(0 25.951)"
        fill="none"
        stroke="#FFFFFF"
        strokeMiterlimit="10"
       strokeWidth="1.5"
      />
    </svg>
  );
};

export default SvgAim;
