import React from "react";

const SvgFigureOne = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.458 81.766">
      <g id="_005" data-name="005" transform="translate(1.5 1.5)">
        <path
          id="Trazado_292"
          data-name="Trazado 292"
          d="M0,15.247A15.5,15.5,0,0,1,15.729,0,15.5,15.5,0,0,1,31.458,15.247"
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="3"
        />
        <path
          id="Trazado_293"
          data-name="Trazado 293"
          d="M0,0V42.781L13.526,56.307,27.052,42.781V0"
          transform="translate(2.203 21.838)"
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
};

export default SvgFigureOne;
