import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import logo_indio_footer_blanco from '../assets/img/logo_indio_footer_blanco.png';
import logo_indio_footer from '../assets/img/logo_indio_footer.png';
import lineas_footer from '../assets/img/Footer/footer-lineas.png';
import lineas_desktop_footer from '../assets/img/Footer/footer-mas-largo-lineas.png';

const Footer = ({ white }) => {
  const [footersrc, guardarSrc] = useState(logo_indio_footer);
  const [footerclass, guardarClass] = useState();

  useEffect(() => {
    if (white) {
      guardarSrc(logo_indio_footer_blanco);
      guardarClass('footer footer-black');
    } else {
      guardarSrc(logo_indio_footer);
      guardarClass('footer ');
    }

    setTimeout(() => {
      const footer = document.querySelector('.footer');
      footer.style.display = 'block';
    }, 1500);
  }, [white]);
  console.log(white)

  // Los estilos css están en el footer están en el index.css
  return (
    <section style={{ display: 'none' }} className={footerclass}>
      <div className='scroll_white'></div>
      <div className='scroll_black'></div>
      <div className='scroll_animacion'></div>
      <div className='footer_content'>
        <img className='logo_indio_footer' src={footersrc} alt='indio' />
        <img className='lineas_footer movil' src={lineas_footer} alt='lineas' />
        <img className='lineas_footer desktop' src={lineas_desktop_footer} alt='lineas' />

        <div className='menu_footer'>
          <div className='footer_icons'>
            <Link className='link' to='/'>
              Home
            </Link>
          </div>

          <div className='footer_icons'>
            <Link className='link' to='/casos'>
              Nuestros trabajos
            </Link>
          </div>

          <div className='footer_icons'>
            <Link className='link' to='/servicios'>
              Nuestros servicios
            </Link>
          </div>

          <div className='footer_icons'>
            <Link className='link' to='/nosotros'>
              Nosotros
            </Link>
          </div>

          <div className='footer_icons'>
            <a className='link' href='https://indio.guru/contacto'>
              Contacto
            </a>
          </div>

          {/* <div className="footer_icons">
                        <a className="link" href="https://indio.guru/oferta">Trabaja con nosotros</a>
                    </div> */}
        </div>

        <div className='politica_privacidad'>
          <p>© Indio.guru</p>
          {/* <p>Política de privacidad</p> */}
        </div>
      </div>
    </section>
  );
};

export default Footer;
