import React from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const SvgLargeArrow = ({ color = "white" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 786.295 162.57">
      <g id="_007" data-name="007" transform="translate(0.648 0.762)">
        <path
          id="Trazado_302"
          data-name="Trazado 302"
          d="M.35.35,95.041,80.873.35,161.4"
          transform="translate(-0.35 -0.35)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          id="Trazado_303"
          data-name="Trazado 303"
          d="M123.56.35l94.663,80.523L123.56,161.4"
          transform="translate(225.122 -0.35)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          id="Trazado_304"
          data-name="Trazado 304"
          d="M243.96.35l94.691,80.523L243.96,161.4"
          transform="translate(445.453 -0.35)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <line
          id="Línea_32"
          data-name="Línea 32"
          x1="689.413"
          transform="translate(95.087 80.86)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default SvgLargeArrow;
