import React from 'react';
import {Link} from 'react-router-dom'


const IndioLogo = () => {
    return (
        <Link className="skip-link" to="/">
            <section className="logo">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1116.5 191.4">
                    <defs>
                    </defs>
                    <g id="Indio">
                        <line className="cls-1" x1="3" y1="6.2" x2="3" y2="188.5" />
                        <line className="cls-1" x1="462" y1="6.2" x2="462" y2="188.5" />
                        <polyline className="cls-1" points="53 186.9 53 7.5 221.7 183.9 221.7 6.5" />
                        <line className="cls-1" x1="272.9" y1="185" x2="272.9" y2="9.1" />
                        <path className="cls-1" d="M438.3,115.4c46.8,0,84.7,39.1,84.7,87.3S485.1,290,438.3,290" transform="translate(-167.9 -105.5)" />
                        <path className="cls-2" d="M495.3,115c46.8,0,84.8,39.2,84.8,87.5s-38,87.5-84.8,87.5" transform="translate(-167.9 -105.5)" />
                        <line className="cls-1" x1="600" y1="8.9" x2="600" y2="184.5" />
                        <circle className="cls-1" cx="600" cy="96.7" r="87.8" />
                    </g>
                    <g id="Digital">
                        <g>
                            <path className="cls-3" d="M985.1,237.4a20.7,20.7,0,0,1,8.8,8.3,24.5,24.5,0,0,1,3.1,12.6,23.9,23.9,0,0,1-3.1,12.5,20.3,20.3,0,0,1-8.8,8.1,29.2,29.2,0,0,1-13.2,2.9H956.5V234.5h15.4A29.2,29.2,0,0,1,985.1,237.4Z" transform="translate(-167.9 -105.5)" />
                            <path className="cls-3" d="M1014.5,285.8V230.4" transform="translate(-167.9 -105.5)" />
                            <path className="cls-3" d="M1097.1,285.8V230.4" transform="translate(-167.9 -105.5)" />
                            <path className="cls-3" d="M1241,282.1h-23.1V231.2" transform="translate(-167.9 -105.5)" />
                            <line className="cls-3" x1="942.3" y1="127.8" x2="980.6" y2="127.8" />
                            <line className="cls-3" x1="961.5" y1="127.8" x2="961.5" y2="180" />
                            <path className="cls-3" d="M1053.7,258.5h26.6v1.7a25.9,25.9,0,0,1-3.6,12,23.6,23.6,0,0,1-8.7,8.5,24.5,24.5,0,0,1-12.3,3.2,24.8,24.8,0,0,1-12.7-3.3,23.4,23.4,0,0,1-9-9.2,27.2,27.2,0,0,1-3.3-13.4,26.8,26.8,0,0,1,3.3-13.3,22.8,22.8,0,0,1,9-9.2,24.8,24.8,0,0,1,12.7-3.3,25.1,25.1,0,0,1,14.2,4c4,2.7,6,5.5,7.8,10.3" transform="translate(-167.9 -105.5)" />
                            <path className="cls-3" d="M1158.5,285l19.5-49.7h3.1l19.4,49.7m-36-15.9h30.3" transform="translate(-167.9 -105.5)" />
                        </g>
                    </g>
                    <g id="Agencia">
                        <path className="cls-4" d="M952,186.5l19.5-49.6h3.1L994,186.5m-36-15.8h30.4" transform="translate(-167.9 -105.5)" />
                        <path className="cls-4" d="M1028.3,159.3h26.6V161a24.5,24.5,0,0,1-3.6,12,23.6,23.6,0,0,1-8.7,8.5,24.2,24.2,0,0,1-12.3,3.2,24.8,24.8,0,0,1-12.7-3.3,24.1,24.1,0,0,1-9-9.2,27.2,27.2,0,0,1-3.3-13.4,26.8,26.8,0,0,1,3.3-13.3,23.4,23.4,0,0,1,9-9.2,24.8,24.8,0,0,1,12.7-3.3,25.2,25.2,0,0,1,14.2,4.1c4.1,2.7,6,5.4,7.8,10.2" transform="translate(-167.9 -105.5)" />
                        <path className="cls-4" d="M1096.6,184.7h-26.9V133h26.9m-26.8,25.4h24.6" transform="translate(-167.9 -105.5)" />
                        <path className="cls-4" d="M1109.7,186.7V134.1a1,1,0,0,1,1.7-.6l34.5,51.6a.7.7,0,0,0,1.2-.4V131" transform="translate(-167.9 -105.5)" />
                        <path className="cls-4" d="M1209,170.2a21.4,21.4,0,0,1-7.8,10.2c-3.8,2.9-8.7,3.9-14.3,3.9a24,24,0,0,1-12.4-3.3,23.1,23.1,0,0,1-8.8-9,28.8,28.8,0,0,1,0-26.4,23.3,23.3,0,0,1,8.8-9.1,26.8,26.8,0,0,1,26.8.8,18.6,18.6,0,0,1,7.7,10.2" transform="translate(-167.9 -105.5)" />
                        <path className="cls-4" d="M1226.8,186.7V131" transform="translate(-167.9 -105.5)" />
                        <path className="cls-4" d="M1240.5,186l19.5-49.7h3.1l19.4,49.7m-36-15.9h30.3" transform="translate(-167.9 -105.5)" />
                    </g>
                </svg>
            </section>
        </Link>

    );
}

export default IndioLogo;