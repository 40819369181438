import React from "react";

const SvgSlash = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.95 72.871">
      <g id="_003" data-name="003" transform="translate(1.15 0.186)">
        <path
          id="Trazado_289"
          data-name="Trazado 289"
          d="M18.345,31.561,0,15.815,18.345,0"
          transform="translate(0 21.063)"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
        <path
          id="Trazado_290"
          data-name="Trazado 290"
          d="M0,72.5,18.413,0"
          transform="translate(24.66)"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};

export default SvgSlash;
