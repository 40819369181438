import React from 'react';

const SvgSpotify = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' fill='#000' version='1.1' viewBox='0 0 305 305' xmlSpace='preserve'>
      <g>
        <path d='M152.441 0C68.385 0 0 68.39 0 152.453 0 236.568 68.385 305 152.441 305 236.562 305 305 236.568 305 152.453 305 68.39 236.562 0 152.441 0zM75.08 208.47c17.674-5.38 35.795-8.108 53.857-8.108 30.676 0 60.96 7.774 87.592 22.49 1.584.863 3.024 3.717 3.67 7.27.646 3.552.389 7.205-.648 9.105-1.309 2.438-3.965 4.014-6.768 4.014a7.773 7.773 0 01-3.831-.972c-24.448-13.438-52.116-20.542-80.015-20.542-16.855 0-33.402 2.495-49.167 7.409a8.084 8.084 0 01-2.348.352c-3.452.001-6.448-2.198-7.453-5.461-1.357-4.461 1.45-14.36 5.111-15.557zm-6.65-56.167a230.72 230.72 0 0160.508-8.071c36.765 0 73.273 8.896 105.601 25.739 2.266 1.15 3.936 3.1 4.701 5.49.776 2.421.542 5.024-.669 7.347-2.885 5.646-6.257 9.44-8.393 9.44-1.514 0-2.975-.363-4.43-1.09-30.019-15.632-62.59-23.558-96.811-23.558-19.035 0-37.71 2.503-55.489 7.435a9.512 9.512 0 01-11.683-6.676c-1.429-5.365 1.836-14.743 6.665-16.056zm-1.703-36.697c-.903.223-1.826.335-2.744.335a11.195 11.195 0 01-10.892-8.487c-1.559-6.323 2.397-13.668 8.126-15.111 22.281-5.473 45.065-8.248 67.72-8.248 43.856 0 85.857 9.86 124.851 29.312a11.17 11.17 0 015.687 6.493c.96 2.854.748 5.926-.592 8.64-1.826 3.655-5.772 7.59-10.121 7.59a11.74 11.74 0 01-4.924-1.109c-35.819-17.921-74.477-27.008-114.9-27.008-20.774.001-41.704 2.555-62.211 7.593z'></path>
      </g>
    </svg>
  );
};

export default SvgSpotify;
