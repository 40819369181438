import React, { useState } from "react";

import "./Testimonies.css";
import information from "./infoTestimonies.json";

import arrow from "../../../../assets/img/boton_flecha_siguiente.png";

import sony from "../../../../assets/img/logos/4_sony.png";
import warner from "../../../../assets/img/logos/3_warner.png";
import valentina from "../../../../assets/img/logos/5_lavalentina.png";
import useWindowWidth from "../../../../hooks/useWindowWidth";
import movistar from "../../../../assets/img/logos/2_movistar.png";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import AnimScroll from "../../../../hooks/AnimScroll";
import { ANIM_DOWN, ANIM_LEFT, ANIM_RIGHT } from "../../Config";

const logos = {
  sony,
  warner,
  valentina,
  movistar,
};

const TestimoniesLayout = () => {
  const responsive = useWindowWidth();

  const [currentInfo, setCurrentInfo] = useState(0);
  const { name, rol, testimonie, logo } = information[currentInfo];

  const handleCurrentInfo = (direction) => {
    const increment = direction === "+" ? 1 : -1;
    const newIndex = (currentInfo + increment + information.length) % information.length;
    setCurrentInfo(newIndex);
  };

  return (
    <div className="max-width">
      <h2 className="title_testimonies">Testimonios</h2>

      <AnimScroll anim={ANIM_DOWN}>
        {responsive < 1024 ? (
          <MobilSlider name={name} rol={rol} testimonie={testimonie} logos={logos} logo={logo} handleCurrentInfo={handleCurrentInfo} />
        ) : (
          <DestockSlider information={information} logos={logos} />
        )}
      </AnimScroll>
    </div>
  );
};

export default TestimoniesLayout;

const MobilSlider = ({ name, rol, testimonie, logos, logo, handleCurrentInfo }) => {
  return (
    <div>
      <div className="testimonies_container">
        <div className="content_wrapper">
          <p className="title_testimonie">{name}</p>
          <p className="rol_testimonie">{rol}</p>
          <p className="paragrapgh_testimonie">{testimonie}</p>

          <div className="img_logo_testimonies">
            <img src={logos[logo]} alt="logo" />
          </div>
        </div>
      </div>

      <div className="container_arrows">
        <img onClick={() => handleCurrentInfo("+")} src={arrow} alt="flecha" />
        <img onClick={() => handleCurrentInfo("-")} src={arrow} alt="flecha" />
      </div>
    </div>
  );
};

const DestockSlider = ({ information, logos }) => {
  return (
    <div className="testimonies_container_destock">
      {information && (
        <Swiper
          navigation={{
            nextEl: ".custom-next",
            prevEl: ".custom-prev",
          }}
          modules={[Navigation]}
          slidesPerView={3}
          spaceBetween={50}
          className="swiper_testimonies"
        >
          {arrow && (
            <div className="arrows_slider_one">
              <div className="custom-prev">
                <img src={arrow} alt="Anterior" />
              </div>
              <div className="custom-next">
                <img src={arrow} alt="Siguiente" />
              </div>
            </div>
          )}

          <AnimScroll anim={ANIM_LEFT}>
            {information.map((item, idx) => (
              <SwiperSlide key={`testimonies_${idx}`} className="testimonies_content_destock">
                <p>{item.name}</p>
                <p>{item.rol}</p>
                <p>{item.testimonie}</p>

                <div className="img_logo_testimonies_destok">
                  <img src={logos[item.logo]} alt="logos" />
                </div>

                <div className="line_bottom_testimonies"></div>
              </SwiperSlide>
            ))}
          </AnimScroll>
        </Swiper>
      )}

      {/* <div className="slider_destock_container">
        {information.map((item, idx) => (
          <div className="testimonies_content_destock" key={`testimonies_${idx}`}>
            <p>{item.name}</p>
            <p>{item.rol}</p>
            <p>{item.testimonie}</p>

            <div className="img_logo_testimonies_destok">
              <img src={logos[item.logo]} alt="logos" />
            </div>

            <div className="line_bottom_testimonies"></div>
          </div>
        ))}
      </div> */}
    </div>
  );
};
