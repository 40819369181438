import React, { useEffect } from 'react'
import './Gracias.css'
import Footer from '../Footer'

import gracias_icono_1 from '../../assets/img/gracias/gracias-icono-1.png'
import gracias_icono_2 from '../../assets/img/gracias/gracias-icono-2.png'
import gracias_flecha from '../../assets/img/gracias/gracias-flecha.png'
import { Link } from 'react-router-dom';


const Gracias = ({ white, guardarWhite, guardarWhiteCaso }) => {

    useEffect(() => {
        window.scrollTo(0, 0);
        guardarWhite(true);
        guardarWhiteCaso(true)
    }, [guardarWhite, guardarWhiteCaso])


    return (
        <section id="gracias">
            <div className="gracias_contenedor">
                <div className="gracias_iconos">
                    <img src={gracias_icono_1} alt="icono" />
                    <img src={gracias_icono_2} alt="icono" />

                </div>
                <div className="gracias_texto">
                    ¡Gracias por <br /> <span>/contactarnos!/</span>
                </div>
                <div className="gracias_flecha">
                    <img src={gracias_flecha} alt="flecha" />
                </div>

            </div>

            <div className="gracias_inicio">
                <Link className="item_menu"
                    to="/">Ir al Inicio</Link>
            </div>

            <div className="gracias_footer">
                <Footer
                    white={white}
                />
            </div>
        </section>
    );
}

export default Gracias;