import React, { useRef } from 'react';
import './Staff.css';

import boton_atras from '../../assets/img/boton_atras.png';
import boton_siguiente_slide from '../../assets/img/boton_siguiente_slide.png';
import 'react-slideshow-image/dist/styles.css';

import foto_mateo from '../../assets/img/Acerca/foto_mateo.png';
import foto_andres from '../../assets/img/Acerca/foto_andres.png';
import foto_laura from '../../assets/img/Acerca/foto_lau.png';
import foto_kevin from '../../assets/img/Acerca/kevin.jpg';
import foto_katherine_ramirez from '../../assets/img/Acerca/foto_katherine_ramirez.png';
import foto_edu from '../../assets/img/Acerca/franco.jpg';
import foto_richard from '../../assets/img/Acerca/richard.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import useWindowWidth from '../../hooks/useWindowWidth';
import SvgSpotify from '../../svg/SvgSpotify';

const teamMembers = [
  {
    name: 'Andrés Toro',
    role: 'Co-fundador y director general',
    album: 'Amnesiac - Radiohead',
    redirect:"https://open.spotify.com/intl-es/album/1HrMmB5useeZ0F5lHrMvl0",
    photo: foto_andres,
  },
  {
    name: 'Mateo París',
    role: 'Co-fundador y director creativo',
    album: 'ROME - Danger Mouse/Daniele Luppi',
    redirect:"https://open.spotify.com/intl-es/album/2nBo5nFoszkjuY0bxv1y0A",
    photo: foto_mateo,
  },
  {
    name: 'Laura Peñuela',
    role: 'Product Designer',
    album: 'Worlds Collide - Apocalytica',
    redirect:"https://open.spotify.com/intl-es/track/1dT9jd1cvrRKL8QPhcW1kI",
    photo: foto_laura,
  },
  {
    name: 'Katherine Ramírez',
    role: 'Community Manager',
    album: 'Days - Real Estate',
    redirect:"https://open.spotify.com/intl-es/track/4TRzOKbFxn19x79sI4gBgL",
    photo: foto_katherine_ramirez,
  },
  {
    name: 'Kevin Jimenez',
    role: 'Desarrollador Full Stack',
    album: 'Vessel - twenty one pilots',
    redirect:"https://open.spotify.com/intl-es/album/2r2r78NE05YjyHyVbVgqFn",
    photo: foto_kevin,
  },
  {
    name: 'Eduardo Franco',
    role: 'Webmaster',
    album: 'NIN - Year Zero',
    redirect:"https://open.spotify.com/intl-es/album/57xyj1jERvCap30Iy9HKJY",
    photo: foto_edu,
  },
  {
    name: 'Richard Cruz',
    role: '	Desarrollador Back-end',
    album: 'Hot Fuss - The Killers ',
    redirect:"https://open.spotify.com/intl-es/album/4piJq7R3gjUOxnYs6lDCTg",
    photo: foto_richard,
  },
];

const Staff = () => {
  const responsive = useWindowWidth();

  return (
    <section id='staff'>
      <div className='desc'>
        <h2>Equipo</h2>

        <Swiper
          navigation={{
            nextEl: '.custom-next',
            prevEl: '.custom-prev',
          }}
          modules={[Navigation]}
          className='swiper_staff'
          slidesPerView={responsive < 764 ? '1' : responsive <= 1024 ? '2' : '3'}
          spaceBetween={20}
        >
          <div className='arrows_slider_staff'>
            <div className='custom-prev'>
              <img src={boton_atras} alt='Anterior' />
            </div>
            <div className='custom-next'>
              <img src={boton_siguiente_slide} alt='Siguiente' />
            </div>
          </div>
          {teamMembers.map((member) => (
            <div key={member.name}>
              <SwiperSlide className='slider_staff'>
                <div className='container_staff'>
                  <div className='shadow_1'></div>
                  <div className='shadow_2'></div>

                  <div className='paddin_staff'>
                    <img src={member.photo} alt='imagen de staff' />
                    <p className='name_staff'>{member.name}</p>
                    <p className='rol_staff'>{member.role}</p>

                    <div className='spotify'>
                      <div className='album_staff'>
                        <p>Album favorito:</p>
                        <p>{member.album}</p>
                      </div>

                      <a href={member.redirect} target='_blank' rel='noopener noreferrer'>
                        <div className='icon_spotify'>
                          <SvgSpotify />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </div>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Staff;
